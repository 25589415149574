import authHeader from "./auth-header";
import { handleResponse } from './handle-response';
import http from "./http-common";
import axios from 'axios';

import { openDB, deleteDB, wrap, unwrap } from 'idb';
import idbcache from 'idbcache';

//const API_URL = "http://localhost:8080/api/test/";
//const API_URL = 'https://rh.casiba.net/api/v1/';
const API_URL = 'https://api.devib.ar/api/v1/';
//const API_URL = 'https://devapi.findemes.ar/api/v1/';
const user = JSON.parse(localStorage.getItem('user'));

/**
 * trae datos de un vencimiento
 * @param {*} id 
 * @returns 
 */
const getVencimiento = (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_vencimiento_id', requestOptions).then(handleResponse));
};

/**
 * trae vencimientos 
 * @param {*} id 
 * @param {*} row 
 * @returns 
 */
const getVencimientos = (recurso) => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'get_vencimientos', requestOptions).then(handleResponse));
};

/**
 * agreda o modifica un vencimiento 
 * @param {*} id 
 * @param {*} row 
 * @returns 
 */
const addmodVencimiento = (id, row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'addmod_vencimiento', requestOptions).then(handleResponse));
};

/**
 * del vencimiento
 * @param {*} id 
 * @param {*} recurso 
 * @returns 
 */
const delVencimiento = (id, recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST', body: data, headers: authHeader() };
  return (fetch(API_URL + 'del_vencimiento', requestOptions).then(handleResponse));
};

export default {

  getVencimiento,
  getVencimientos,
  addmodVencimiento,
  delVencimiento,

};
