import { makeStyles } from '@mui/styles';
import { createTheme } from "@mui/material/styles";

export const direction = "ltr";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  modal: {
    position: 'absolute',
    width: 400,
    backgroundColor: '#fff',
    border: '2px solid #000',
    boxShadow: 5,
    padding: (2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  iconos: {
    cursor: 'pointer'
  },
  inputMaterial: {
    width: '100%'
  },
  formControl: {
    margin: 1,
    minWidth: 120,
    width: '100%'
  },
  selectEmpty: {
    marginTop: 2,
  },
  cardHeader: {
    padding: (1, 2),
  },
  list: {
    width: 200,
    height: 230,
    backgroundColor: '#fff',
    overflow: 'auto',
  },
}));


export const useTheme = createTheme(
  {
    direction: direction,
    palette: {
      mode: "light",
    },
  }
);

export const theme = createTheme(
  {
    direction: direction,
    palette: {
      mode: "light",
    },
  }
);
