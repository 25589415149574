/**
 * Intranet
 * update 06/2022
 * By Sergio Sam 
 */

import React from 'react';

import { makeStyles, useTheme } from '@mui/styles';

import Paper from '@mui/material/Paper';
import { Button, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';

import UserService from "../../services/user.service";

import { useState, useEffect } from 'react'
import { useParams } from 'react-router';

import { useFormik } from 'formik';
import * as yup from 'yup';

//Validacion del formulario
const validationSchema = yup.object({
    dominio: yup
        .string('Dominio requerido')
        .required('Dominio requerido'),
    empresa: yup
        .string('Empresa requerida')
        .required('Empresa requerida'),
});

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
    modal: {
        backgroundColor: '#fff',
        border: '2px solid #000',
        boxShadow: 5,
        padding: (2, 4, 3),
    },
    iconos: {
        cursor: 'pointer'
    },
    inputMaterial: {
        width: '100%'
    },
    formControl: {
        margin: 1,
        minWidth: 120,
        width: '100%'
    },
    selectEmpty: {
        marginTop: 2,
    },
    cardHeader: {
        padding: (1, 2),
    },
    list: {
        width: 200,
        height: 230,
        backgroundColor: '#fff',
        overflow: 'auto',
    },
}));

export default function Vencimientoaddmod(props) {

    //inicializacion de variables y validacion
    var d = new Date();
    var finaldate = d.getFullYear() + "-" + (d.getMonth() + 1).toString().padStart(2, "0") + "-" + (d.getDate()).toString().padStart(2, "0")

    const today = new Date().toISOString().substr(0, 10);

    //inicializacion de variables y validacion
    const formik = useFormik({
        initialValues: {
            dominio: '',
            empresa: '',
            fecha_vencimiento: '',
            alerta: '',
            descripcion: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            peticionPost(values);
        },
    });

    const { id } = useParams();

    const styles = useStyles();
    const classes = useStyles();

    const [checked, setChecked] = useState([]);
    const [data, setData] = useState([]);
    const [datos, setDatos] = useState([]);

    const [datayacimientos, setDatayacimientos] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);

    const [plantas, setPlantas] = useState([
        { id: 'Mitre', nombre: 'Mitre' },
        { id: 'Sabattini', nombre: 'Sabattini' },
    ]);

    const [tipos, setTipos] = useState([
        { id: 'Mantenimiento Preventivo', nombre: 'Mantenimiento Preventivo' },
        { id: 'Mantenimiento Correctivo', nombre: 'Mantenimiento Correctivo' },
        { id: 'Mantenimiento Predictivo', nombre: 'Mantenimiento Predictivo' },
        { id: 'Mantenimiento de Rutina', nombre: 'Mantenimiento de Rutina' },
        { id: 'Mantenimiento Correctivo de Emergencia', nombre: 'Mantenimiento Correctivo de Emergencia' },
    ]);

    const peticionPost = async (values) => {
        const response = await UserService.addmodVencimiento(id, values);
        cerrarEditar()
    }

    const cerrarEditar = () => {
        props.history.push(process.env.PUBLIC_URL + "/home");
    }

    useEffect(() => {

        const GetData = async () => {

            if (id) {
                try {
                    const response = await UserService.getVencimiento(id);

                    if (response) {
                        formik.setValues(response);
                    } else {
                        props.history.push(process.env.PUBLIC_URL + "/login");
                    }
                } catch (e) {
                    props.history.push(process.env.PUBLIC_URL + "/login");
                }
            }

        }
        GetData();

    }, []);

    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/")
    }


    const vencimiento = () => {
        props.history.push(process.env.PUBLIC_URL + "/home")
    }

    return (
        <Paper className={classes.root}>

            <Breadcrumbs aria-label="breadcrumb">
                <Typography style={{ cursor: 'pointer' }} variant="button" color="textSecondary" onClick={() => inicio()}>
                    Inicio
                </Typography>
                <Typography style={{ cursor: 'pointer' }} variant="button" color="textSecondary" onClick={() => vencimiento()}>
                    Vencimientos
                </Typography>
            </Breadcrumbs>
            <br></br>

            <div className={styles.modal}>
                <form onSubmit={formik.handleSubmit}>
                    <h3>Agregar/Modificar Vencimiento</h3>

                    <Grid container spacing={3}>

                        <Grid item xs={6}>
                            <TextField
                                name="dominio"
                                className={styles.inputMaterial}
                                label="Dominio"
                                autoComplete='off'
                                value={formik.values.dominio}
                                onChange={formik.handleChange}
                                error={formik.touched.dominio && Boolean(formik.errors.dominio)}
                                helperText={formik.touched.dominio && formik.errors.dominio}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                name="empresa"
                                className={styles.inputMaterial}
                                label="Empresa"
                                autoComplete='off'
                                value={formik.values.empresa}
                                onChange={formik.handleChange}
                                error={formik.touched.empresa && Boolean(formik.errors.empresa)}
                                helperText={formik.touched.empresa && formik.errors.empresa}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="date-proximo"                        // Nuevo campo
                                type="date"
                                autoComplete='off'
                                name="fecha_vencimiento"                     // Nuevo campo
                                label="Fecha vencimiento"                      // Nuevo campo
                                className={classes.textField}
                                error={formik.touched.fecha_vencimiento && Boolean(formik.errors.fecha_vencimiento)}
                                helperText={formik.touched.fecha_vencimiento && formik.errors.fecha_vencimiento}
                                onChange={formik.handleChange}
                                value={formik.values.fecha_vencimiento}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formik.values.alerta === 'Si'}
                                            onChange={(event) => formik.setFieldValue('alerta', event.target.checked ? 'Si' : '')}
                                            name="alerta"
                                            value="Si"
                                            color="primary"
                                        />
                                    }
                                    label="Sí (Enviar alerta)"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formik.values.alerta === 'No'}
                                            onChange={(event) => formik.setFieldValue('alerta', event.target.checked ? 'No' : '')}
                                            name="alerta"
                                            value="No"
                                            color="primary"
                                        />
                                    }
                                    label="No (No enviar alerta)"
                                />
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                name="descripcion"
                                className={styles.inputMaterial}
                                label="Descripcion"
                                autoComplete='off'
                                value={formik.values.descripcion}
                                onChange={formik.handleChange}
                                error={formik.touched.descripcion && Boolean(formik.errors.descripcion)}
                                helperText={formik.touched.descripcion && formik.errors.descripcion}
                            />

                            <TextField
                                name="data1"
                                type="hidden"
                                value={formik.values.data1}
                            />

                            <TextField
                                name="recurso"
                                type="hidden"
                                value={formik.values.recurso}
                            />

                        </Grid>

                    </Grid>
                    <br /><br />
                    <div align="right">
                        <Button color="primary" type="submit">Agregar</Button>
                        <Button onClick={() => cerrarEditar()}>Cancelar</Button>
                    </div>
                </form>
            </div>
        </Paper>
    );
}