/**
 * Mine
 * By Sergio Sam 
 */

import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { makeStyles, useTheme } from '@mui/styles';
import { mdiCart, mdiFactory, mdiBookMultipleOutline, mdiBookMultiple, mdiPackageVariant, mdiCar, mdiClipboardListOutline, mdiBagChecked, mdiArchiveCogOutline } from '@mdi/js'
import { mdiArchiveCog } from '@mdi/js';
import { mdiCogOutline } from '@mdi/js';
import { mdiCardAccountDetails } from '@mdi/js';

import Grid from '@mui/material/Grid';

import Icon from '@mdi/react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import { AuthContext } from '../components/AuthContext';
import  HomeOwner  from '../components/HomeOwner.component';

import { ServiceWorkerUpdateListener } from '../ServiceWorkerUpdateListener.js'
import { useStyles } from './styles'; // Importa los estilos desde el archivo styles.js

const Home = (props) => {
  const [updateWaiting, setUpdateWaiting] = useState(false);
  const [registration, setRegistration] = useState(null);
  const [swListener, setSwListener] = useState({});

  const [data, setData] = useState([]);

  const { currentUser,showOwner, showMaster, showAdmin, showUser } = useContext(AuthContext);

  useEffect(() => {

    if (process.env.NODE_ENV !== "development") {
      let listener = new ServiceWorkerUpdateListener();
      setSwListener(listener);
      listener.onupdateinstalling = (installingEvent) => {
        console.log("SW installed", installingEvent);
      };
      listener.onupdatewaiting = (waitingEvent) => {
        console.log("New update waiting", waitingEvent);
        setUpdateWaiting(true);
      };
      listener.onupdateready = (event) => {
        console.log("Updateready event");
        window.location.reload();
      };
      navigator.serviceWorker.getRegistration().then((reg) => {
        listener.addRegistration(reg);
        setRegistration(reg);
      });

      return () => listener.removeEventListener(null, null);
    } else {
      //do nothing because no sw in development
    }


  }, []);

  const handleUpdate = () => {
    swListener.skipWaiting(registration.waiting);
  }

  const classes = useStyles();

  return (

    <>
      <UpdateWaiting updateWaiting={updateWaiting} handleUpdate={handleUpdate} />

      <sections>

        {showOwner && (
          <HomeOwner />
        )}

    
      </sections>
    </>

  );
};

export default Home;

const UpdateWaiting = ({ updateWaiting, handleUpdate }) => {
  if (!updateWaiting) return <></>
  return (
    <Alert variant="filled" severity="error" onClick={handleUpdate}>
      Versión nueva disponible, Pulsa aquí para Actualizar!
    </Alert>
  )
}