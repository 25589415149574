// AuthContext.js

import React, { createContext, useState, useEffect } from 'react';
import AuthService from "../services/auth.service";
import { useHistory } from "react-router-dom";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState(null);
  const [showUser, setShowUser] = useState(false);
  const [showAdmin, setShowAdmin] = useState(false);
  const [showMaster, setShowMaster] = useState(false);
  const [showOwner, setShowOwner] = useState(false);
  const [showProducts, setShowProducts] = useState(false);

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      setCurrentUser(user);
      setShowUser(user.roles.includes('ROLE_USER'));
      setShowAdmin(user.roles.includes('ROLE_ADMIN'));
      setShowMaster(user.roles.includes('ROLE_MASTER'));
      setShowOwner(user.roles.includes('ROLE_OWNER'));
      setShowProducts(user.availableProducts);
      console.log(user.availableProducts);
    } else {
      history.push(process.env.PUBLIC_URL + "/login");
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        showUser,
        showAdmin,
        showMaster,
        showOwner,
        showProducts,        
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
