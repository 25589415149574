/**
 * App Horario
 * update 02/2023
 * By Sergio Sam 
 */

import React, { useState, useRef } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { makeStyles, useTheme} from '@mui/styles';

import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';



import { useHistory } from "react-router-dom";

import AuthService from "../services/auth.service";
import UserService from "../services/user.service";

import fondo from '../fondo.jpg';

import * as yup from 'yup';
import { useFormik } from 'formik';

var mailant = "";
var resmailant = false;

var empresaant = "";
var resempresaant = false;

const checkEmail = async (values) => {
  const response = await UserService.validEmails(values);
  return response.data;
}

//const checkEmpresa = async (values) => {
//  const response = await UserService.validEmpresa(values);
//  return response.data;
//}

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mine.com.ar/">
        Mine
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url('+fondo+')',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#fff',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: (8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: (1),
    backgroundColor: '#fff',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: 1,
  },
  submit: {
    margin: (3, 0, 2),
  },
}));
//Validacion del formulario
const validationSchema = yup.object({
  nombre: yup
    .string('Tu nombre es requerido')
    .required('Tu nombre requerido'),
  email: yup
    .string('Email requerido')
    .email('Email no valido')
    .required('Email requerido')
    .test('checkDuplUsername', 'El email ya esta en uso, utilize otro email', async function (value) {
      if (value) {
        if (value !== mailant) {
          mailant = value;
          const res = await checkEmail(value);
          if (res) {
            resmailant = false;
            return false;
          } else {
            resmailant = true;
            return true;
          }
        } else {
          return resmailant;
        }
      }
    }),
  //empresa: yup
  //  .string('Nombre de empresa requerido')
  //  .required('Empresa requerido')
  //  .test('checkDuplUsername', 'El nombre de empresa ya esta en uso, utilize otro nombre', async function (value) {
  //    if (value) {
  //      if (value !== empresaant) {
  //        empresaant = value;
  //        const res = await checkEmpresa(value);
  //       if (res) {
  //          resempresaant = false;
  //          return false;
  //        } else {
  //          resempresaant = true;
  //          return true;
  //        }
  //      } else {
  //        return resempresaant;
  //      }
  //    }
  //  }),
  password: yup.string()
    .required('Password es requerido')
    .min(6, 'Password debe tener al menos 6 caracteres')
    .max(40, 'Password no debe exeder los 40 caracteres'),
  confirmPassword: yup.string()
    .required('Confirm Password es requerido')
    .oneOf([yup.ref('password'), null], 'Confirm Password no coinciden'),
  acceptTerms: yup.bool().oneOf([true], 'Debes aceptar los terminos y condiciones'),
});

const Register = (props) => {
  const [updateWaiting, setUpdateWaiting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const classes = useStyles();
  const form = useRef();
  const checkBtn = useRef();
  const [show, setShow] = useState(true);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  //inicializacion de variables y validacion
  const formik = useFormik({
    initialValues: {
      nombre: '',
      email: '',
      password: '',
      //empresa: '',
      acceptTerms: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      peticionPost(values);
      //alert(JSON.stringify(values, null, 2));
    },
  });

  const peticionPost = async (values) => {
    const res = await UserService.register(values);

    if (res.data == 'producto') {
      setUpdateWaiting(true);
      setShow(false);
    } else {
      //setUpdateWaiting(true);
      cerrarEditar();
    }

  }

  const [shown, setShown] = useState(false);
  const [shown1, setShown1] = useState(false);

  const switchShown = () => setShown(!shown);
  const switchShown1 = () => setShown1(!shown1);

  const cerrarEditar = () => {
    history.push("/home");
  }

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.login(username, password).then(
        () => {

          const GetProduct = async () => {
            try {
              setIsLoading(true);
              const result = await UserService.getProduct('horario');
              if (result.data) {
                setIsLoading(false);
                history.push(process.env.PUBLIC_URL + "/home");
                window.location.reload();
              } else {
                AuthService.logout();
                history.push(process.env.PUBLIC_URL + "/login");
                window.location.reload();
              }
            } catch (e) {
              history.push(process.env.PUBLIC_URL + "/login");
            }
          }
          GetProduct();

        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          setMessage(resMessage);
        }
      );
    } else {
      setLoading(false);
    }
  };

  const handleUpdate = () => {
    setUpdateWaiting(false);
    cerrarEditar();
  }

  const abrirCerrarModalInsertar = () => {
    history.push("/register/")
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Crear Cuenta
          </Typography>

          <UpdateWaiting updateWaiting={updateWaiting} handleUpdate={handleUpdate} />

          {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}

          {show && (
            <Form className={classes.form} noValidate onSubmit={formik.handleSubmit} >

              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="nombre"
                label="Nombre"
                autoComplete="false"
                autoFocus
                name="nombre"
                value={formik.values.nombre}
                onChange={formik.handleChange}
                error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                helperText={formik.touched.nombre && formik.errors.nombre}
              />

              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Direccion de email"
                autoComplete="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />

              <TextField
                type={shown ? 'text' : 'password'}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="password"
                label="Password"
                autoComplete="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />

              <span class="input-group-btn">
                <button class="btn btn-default" type="button" onClick={switchShown}>
                  {shown ? 'Ocultar' : 'Ver'}
                </button>
              </span>

              <TextField
                type={shown1 ? 'text' : 'password'}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="confirmPassword"
                label="Confirm Password"
                autoComplete="confirmPassword"
                name="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
              />

              <span class="input-group-btn">
                <button class="btn btn-default" type="button" onClick={switchShown1}>
                  {shown1 ? 'Ocultar' : 'Ver'}
                </button>
              </span>

              <input type="hidden" name="producto" class="form-control"
                value={formik.values.producto}
                onChange={formik.handleChange}
                error={formik.touched.producto && Boolean(formik.errors.producto)}
                helperText={formik.touched.producto && formik.errors.producto}
              />

              <div className="form-group form-check">
                <input
                  name="acceptTerms"
                  type="checkbox"
                  className="form-check-input"
                  value={formik.values.acceptTerms}
                  onChange={formik.handleChange}
                  error={formik.touched.acceptTerms && Boolean(formik.errors.acceptTerms)}
                  helperText={formik.touched.acceptTerms && formik.errors.acceptTerms}
                />

                <label htmlFor="acceptTerms" className="form-check-label">
                  He leido y acepto los terminos y condiciones
                </label>
                <CheckButton style={{ display: "none" }} ref={checkBtn} />

                {formik.errors.acceptTerms &&
                  <div>
                    {formik.errors.acceptTerms}
                  </div>}
              </div>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Registrarse
              </Button>


            </Form>
          )}

        </div>
      </Grid>
    </Grid>
  );
};
export default Register;

const UpdateWaiting = ({ updateWaiting, handleUpdate }) => {
  if (!updateWaiting) return <></>
  return (
    <Alert variant="filled" severity="error" onClick={handleUpdate}>
      Ya posees cuenta activa se agrego la nueva funcionalidad a tu cuenta, Pulsa aquí para Cerrar!
    </Alert>
  )
}

